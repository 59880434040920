import React from 'react'
import "./UnderMaintenence.scss"
import { assets } from './Assets/Assets'
import { IoIosCall } from 'react-icons/io'


const UnderMaintenence = () => {
  return (

    <div className='maintenece'>

      <div className="logo">
        <div className="logo_warapper">
            <img src={assets.logo} alt="" />
        </div>

      </div>

      {/* <div className="maintenece_content_wrapper"> */}

      
        
        <div className="content_wrapper">
            <div className="img_wrapper">
              <img src={assets.emptyImg} alt="" />
              </div>
              <h5>We Are Currently Under Maintenance</h5>
              <span>
              Our website is temporarily unavailable for scheduled maintenance. 
              We’ll be back  shortly!
              </span>
              {/* <div className="icon_number">
              <IoIosCall />

                <span>+04833560621</span>
              </div>
          */}
        </div>

        {/* <div className="baground_Ellipse">
          <img src={assets.Ellipse} alt="" />
        </div>

        </div> */}
    </div>
  )
}

export default UnderMaintenence