
import emptyImg from "../Assets/Images/Ilustration.svg"
import Ellipse from "../Assets/Images/Ellipse 1.png"
import logo from "../Assets/Images/,.png"


export const assets = {

    emptyImg,
    Ellipse,
    logo


};

