
import './App.css';
import UnderMaintenence from './Components/UnderMaintenece/UnderMaintenence';

function App() {
  return (
    <div className="App">

    <UnderMaintenence/>

    </div>
  );
}

export default App;
